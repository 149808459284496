import { Config } from "./types";

const config: Config = {
    API_URL: "https://dev-api.fitforwork.ai/api/v2",
    BUGSNAG_KEY: "bf5b0d515b60142658aed82461fb95c7",
    AUTH_0: {
        AUTH_CLIENT_ID: '79PdiFy8Jq05yW7YUVw9Og0sAX1eVwVn',
        AUTH_DOMAIN: 'facecheck-dev.eu.auth0.com',
        AUTH_AUDIENCE: 'dev.fitforwork.ai',
        REDIRECT_URL: 'https://portal-dev.fitforwork.ai/users',
    },
};

export default config;
